.section-one {
  text-align: center;
  height: 100vh;
  min-height: 600px;
  background: linear-gradient(#049bff,#0044ff);
}

html, body {
  overflow-x:hidden;
  height: 100%;
} 

#title-holder {
  flex-direction: row;
  z-index: 100;
  margin-left: 100px;
}

#bg-round {
  left: 40%;
}

#subtitle {
  color: white;
  margin-bottom: 0px;
  font-size: 24px;
}

.section-two {
  text-align: left;
  min-height: 500px;
  padding: 50px;
  position: relative;
  background-color: rgba(230, 240, 255, 0.397);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.section-two::before {
  content: " ";
  position: absolute;
  -webkit-transform: skewY(-50deg);
  transform: skewY(-3deg);
  pointer-events: none;
  top: 50%;
  left: 5%;
  width: 90%;
  height: 35%;
  background: linear-gradient(rgba(0, 0, 0, 0),rgba(200, 201, 255, 0.2));
}

.section-three {
  text-align: left;
  min-height: 300px;
  background-color: rgba(230, 240, 255, 0.397);
  color: rgb(26, 31, 53);
}

.section-four {
  position: relative;
  text-align: left;
  min-height: 600px;
  height: 100vh;
  background: linear-gradient(#0044ff, #002eac);
}

.section-four p {
  color: rgb(250, 250, 250);
}

.section-four::before {
  content: " ";
  position: absolute;
  -webkit-transform: skewY(-50deg);
  transform: skewY(-10deg);
  pointer-events: none;
  top: 30%;
  left: 20%;
  width: 60%;
  height: 35%;
  background: linear-gradient(hsla(0,0%,100%,0),hsla(0,0%,100%,.075));
}

footer {
  /* background-color: #F6F9FC; */
  background: linear-gradient(#31498a, #162f75);
  height: 400px;
  text-align: left;
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

footer h6 {
  color: rgb(255, 255, 255);
}

.header-links {
  padding: 10px;
  border-bottom: 3px solid rgba(255,255,255,0);
  color: white;
  display: inline-block;
  float: right;
  margin: 20px;
  transition-property: border-bottom;
  transition-duration: 500ms;
}

.header-links:hover {
  border-bottom: 3px solid rgb(255, 255, 255);
  transition-property: border-bottom;
  transition-duration: 500ms;
}

.section-two-logo {
  height: 110px;
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
}

.section-two-logo-img {
  height: 110px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1200px) {
  .section-two-logo {
    height: 75px;
    width: 100%;
    text-align: center;
  }
  .section-two-logo-img {
    height: 75px;
  }
}


#mission {
  font-size: 60px;
  margin-left: 65px;
  margin-top: 5%;
  float: left;
  width: 100%; 
}

#mission-statement {
  text-align: left;
  margin-left: 65px;
  margin-right: 65px;
}
#mission-underline {
  margin-bottom: 1rem;
  border: 0;
  position: relative;
  margin: 20px;
  margin-left: 65px;
  margin-top: 17.5%;
  border-top: 5px solid rgb(230, 240, 255);
}

#title {
  color: white;
  margin-top: 10%;
  margin-bottom: 5%;
  font-size: 90px;
}

#phone-background-2 {
  display: none;
}

#app-screen-2 {
  height: 460px;
  width: 213px;
  position: absolute;
  transform: translateX(-44%);
  z-index: -1;
  top: 15px;
  margin-left: -180px;
}

@media only screen and (max-width: 1200px) {
  #title-holder {
    flex-direction: column;
  }
  #title {
    margin-top: 10%;
    font-size: 50px;
  }
}

@media only screen and (max-width: 1028px) {
  #bg-round {
    left: 420px;
  }
  #phone-background {
    display: none;
  }
  #phone-background-2 {
    display: inline;
    position: relative;
  }
  .code-2 {
    margin-left: 0!important;
  }
  #mission-underline {
    display: none;
  }
}

@media only screen and (max-width: 461px) {
  .section-three {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 768px) {
  .header-links {
    color: white;
  }
  #title-holder {
    margin-left: 5%;
  }
  #banner-text {
    font-size: 13px!important;
  }
  #bg-round {
    left: 40%;
  }
  #mission-statement {
    margin-left: 7.5%;
    margin-top: 100;
  }
  #mission {
    margin-left: 7.5%;
    margin-top: 80px;
    font-size: 45px;
  }
  #mission-underline {
    margin-top: 20%;
  }
  .section-two {
    text-align: left;
  }
  #section-one-sign-two {
    display: none;
  }
  .section-three {
    min-height: 400px;
  }
  #title {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 568px) {
  #testflight-link {
    font-size: 15px !important;
    margin-bottom: 50px;
  }

  #title {
    margin-top: 10%;
    font-size: 50px;
  }
  #subtitle {
    color: white;
    margin-bottom: 30px;
    font-size: 16px;
  }
  /* #header-name {
    margin: 14px;
    margin-top: 20px;
  }
  #title {
    font-size: 50px!important;
  }
  #section-one-sign-two {
    display: none;
  }
  .section-three {
    min-height: 500px;
    margin-top: 50;
  }*/
  footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 410px) {
  .header-links {
    margin-top: 45px;
  }
  #app-screen-2 {
    margin-left: 0px;
  }
  #header-name {
    margin: 10px;
    font-size: 19px!important;
    margin-top: 15px!important;
  }
  #section-one-sign-two {
    display: none;
  }
  .section-two {
    min-height: 1325px;
  }
  #title {
    margin-top: 20;
    font-size: 35px!important;
  }
  #subtitle {
    color: white;
    margin-right: 10%;
    margin-bottom: 30px;
    font-size: 16px;
  }
  h1 {
    font-size: 40px!important;
  }
  h2 {
    font-size: 23px!important;
  }
  .header-links-mob {
    margin: 5px;
    font-size: 18px;
    top: 10px;
  }
  #header-name {
    margin: 10px;
  }
  code {
    font-size: 22px!important;
  }
}


.modal-content {
  border: none!important;
  border-radius: 0px!important;
}

@keyframes iphone-bg {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.iphone-bg {
  animation-name: iphone-bg;
  animation-duration: 1s;
}

@keyframes iphone-fg {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.iphone-fg {
  animation-name: iphone-fg;
  animation-duration: 1s;
}