@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4,h5,h6,p,button {
  color: #30343F;
}

html, body { overflow-x: hidden; }

button:focus {outline:0!important;}

.modal-backdrop {
  opacity:0.5 !important;
}

.modal-header {
  border: none!important;
}

.modal-body {
  padding: 20px!important;
}

.btn,.btn-primary {
  background-color: rgb(21, 29, 68)!important;
  border-radius: 0px!important;
  border: none!important;
}

a {
  text-decoration: none!important;
}

.alert {
  position: fixed !important;
  z-index: 1000;
  top: 30px;
  right: 30px;
}

@keyframes move {
  0% {
    transform: translate3d(0,0,0);
  }

  25% {
    transform: translate3d(-3%,-2%,0);
  }

  75% {
    transform: translate3d(-2%,-3%,0);
  }

  100% {
    transform: translate3d(0,0,0);
  }
}

.background-animation {
  animation: move 10s infinite ease-in-out;
}